export default {
    "FUNDRAISING_PAGE_TEAM_TYPE_ID": "T",
    "FUNDRAISING_PAGE_INDIVIDUAL_TYPE_ID": "S",   
    "FUNDRAISING_PAGE_SORT_BY_TOTAL_STEP":"TotalStep",
    "FUNDRAISING_PAGE_SORT_BY_TOTAL_DISTANCE":"TotalDistance",
    "FUNDRAISING_PAGE_SORT_BY_TOTAL_TIME":"TotalTime",
    "FUNDRAISING_PAGE_SORT_BY_TOTAL_ENERGY":"TotalEnergy",
    "FUNDRAISING_PAGE_SORT_BY_TEAM_TOTAL":"TeamTotal",
    "FUNDRAISING_PAGE_SORT_BY_TOTAL":"Total",
    "CURRENCY" : "$",

    "EVENT_PAGE_SORT_BY_TOTAL":"Total",
    "EVENT_PAGE_SORT_BY_TOTAL_STEP":"TotalStep",
    "EVENT_PAGE_SORT_BY_TOTAL_DISTANCE":"TotalDistance",
    "EVENT_PAGE_SORT_BY_TOTAL_TIME":"TotalTime",
    "EVENT_PAGE_SORT_BY_TOTAL_ENERGY":"TotalEnergy",
    "FUNDRAISER_SEARCH_DEFAULT_MODE":"fundraisers",

    "LOAD_MORE_OPTION_FIXED" : "fixed",
    "LOAD_MORE_OPTION_INFINITE" : "infinite",
    "LOAD_MORE_OPTION_LOADMORE" : "loadMore",
}