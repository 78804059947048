<template lang="html">
  <div id="vue-gf-event-leaderboard">
    <div class="container">
      <div class="row mb-2">
        <div class="h1 gf-elb-title">
          {{ title }}
        </div>
      </div>
      <div
        v-for="(event, index) in events"
        :key="index"
        class="row"
      >
        <div
          class="col-2 border p-0 m-0 gf-elb-position"
        >
          {{ index + 1 }}
        </div>
        <div class="col-6 border p-0 m-0 gf-elb-name">
          <a :href="event.EventUrl">{{ event.EventName }}</a>
        </div>
        <div class="col-4 border p-0 m-0 gf-elb-raised">          
          <Counters
            :sort-by="pageSortBy"
            :region-code="pageRegionCode"
            :total-step-in-number="event.TotalRaw"
            :total-distance-in-meters="event.TotalRaw"
            :total-activity-duration-in-seconds="event.TotalRaw"
            :total-energy-in-calories="event.TotalRaw"
            :total-value="event.TotalRaw"
          />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import axios from 'axios';
import 'promise-polyfill/src/polyfill';
import constants from '../common/constants';
import Base from './Base.vue';
import Counters from './Counter/Counters.vue'

export default {
  name: 'EventsLeaderBoard',
  components: { Counters },
  mixins: [Base],

  props: {
    title: {
      type: String,
      default: 'Top Events'
    },
    searchString: {
      type: String,
      default: ""
    },
    pageSortBy: {
      type: String,
      default: "Total"
    },
    pageSortOrder: {
      type: String,
      default: "desc"
    },
    pageRegionCode: {
        type: String,
        default: ""
    },
  },

  data: function() {
    return {      
      events: [],
      driverButtonColour: '',
      driverTextColour: '',
    };
},

  mounted: function() {        
    this.populateLeaderBoard();
  },

  methods: {    
    async populateLeaderBoard() {
      var vm = this;

      var eventSearchUrl = `https://${this.apiDomain}/v1/events/search?query=${this.searchString}&pagesize=${this.size}&sortorder=${this.pageSortOrder}&sortby=${this.pageSortBy}`;
      if (this.eventId > 0)
        eventSearchUrl = `https://${this.apiDomain}/v1/events/search?eventcampaignid=${this.eventId}&includesubevents=true&pagesize=${this.size}&sortorder=${this.pageSortOrder}&sortby=${this.pageSortBy}`;
      else if (this.searchString === "")
        return;
      await axios.get(eventSearchUrl).then(res => {
        if (res.status === 200)
          vm.constructEvents(res.data);
      });
    },

    constructEvents(data) {      
      var vm = this;
      data.Events.forEach((elem, index)  => {
        
        var total = 0;
        switch (this.pageSortBy){
            case constants.EVENT_PAGE_SORT_BY_TOTAL:
              total = elem.EventTotal;
              break;            
            case constants.EVENT_PAGE_SORT_BY_TOTAL_STEP:             
              total = elem.ActivityData.TotalStepInNumber;
              break;  
            case constants.EVENT_PAGE_SORT_BY_TOTAL_DISTANCE:
              total = elem.ActivityData.TotalDistanceInMeters;
              break;  
            case constants.EVENT_PAGE_SORT_BY_TOTAL_TIME:
              total = elem.ActivityData.TotalTimeInMinutes;
              break;  
            case constants.EVENT_PAGE_SORT_BY_TOTAL_ENERGY:
              total = elem.ActivityData.TotalEnergyInCalories;
              break;
          }
          
          elem = Object.assign(elem, {
            "TotalRaw": total.toString()
          });

          vm.events.push(elem);
        
      });
     
    }
  }
}
</script>

<style lang="css" scoped>

</style>
