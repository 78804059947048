<template lang="html">
  <div
    class="h-100 w-100"
  >
    <a
      :href="fundraiser.pageUrl"
      target="_blank"
    >
      <vue-flip
        :active-hover="true"
        width="100%"
        height="100%"
        class="simple-test"
      >
        <div
          slot="front"
          class="h-100 w-100"
        >
          <img            
            class="image-cover h-100 w-100"
            :src="processedImageUrl"
            :alt="fundraiser.name"
          >
          <div
            class="bottom row image-overlay-text m-0 text-center w-100 front-image-overlay"
            :style="textColorStyle"
          >
            <div class="align-center font-weight-bold text-center w-100">
              <span class="text-light">${{ raised }}</span>
            </div>
          </div>
        </div>
        <div
          slot="back"
          class="h-100 w-100"
        >          
          <div            
            class="h-100 w-100"
            :style="style"
          >
            <div
              class="centered row image-overlay-text m-0 text-center"
              :style="textColorStyle"
            >
              {{ fundraiser.name }}
              <br>
              <div class="align-center font-weight-bold text-center w-100">${{ raised }}</div>
            </div>
          </div>
        </div>
      </vue-flip>
    </a>
  </div>
</template>

<script>
import VueFlip from './Flip';
export default {
  name: 'FundraiserBlock',
  components: {
    'vue-flip': VueFlip
  },
  props: {
    fundraiser: {
      type: Object,
      default: () => {}
    },

    blockBackgroundColor: {
      type: String,
      default:''
    },

    textColor: {
      type: String,
      default:''
    },
  },

  computed: {
    style() {
      return `background-color: ${this.blockBackgroundColor}`
    },

    textColorStyle() {
      return `color: ${this.textColor}`
    },

    raised() {
      return parseFloat(this.fundraiser.raised).toFixed(2).replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,')
    },

    processedImageUrl() {
      return this.fundraiser.imageUrl.replace(/\/upload\//ig, '/images/300/Upload/');
    }
  }
}
</script>

<style lang="css">
.image-cover {
  object-fit: cover;
}

.centered {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.bottom {
  position: absolute;
  top: 90%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.image-overlay-text {
  color: black;
  font-size: 1.1em;
}

.front-image-overlay {
  background-color:rgba(46, 49, 49, 0.5)
}

</style>
