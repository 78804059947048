<template>
  <div
    v-if="fundraiserSearchResults"
    id="search-result"
  >     
    <div class="header-container-mobile inner-wrap">
      <div id="search-items">             
        <div
          v-infinite-scroll="loadMore"
          infinite-scroll-disabled="loading"
          infinite-scroll-distance="10"
          infinite-scroll-throttle-delay="200"
          infinite-scroll-immediate-check="false"
        >		
          <FundraiserBoxRaise
            v-for="(page, index) of fundraiserSearchResults" 
            :id="index"
            :key="index"
            :page="page"
            :hide-join-team="hideJoinTeam"
          />

          <loader
            :color="spinnerColour"
            :loading="loading"
            size="36px"
          />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Loader from "vue-spinner/src/ClipLoader.vue";
import FundraiserBoxRaise from './FundraiserBoxRaise.vue'
export default {
    components: {FundraiserBoxRaise, Loader},
    props:{
        fundraiserSearchResults: {
            type: Array,
            default: () => []
        },
        loading: {
            type:Boolean,
            default: false
        },
        spinnerColour: {
            type: String,
            default: "#000"
        },
        hideJoinTeam: {
            type: Boolean,
            default: false
        },
    },    
    methods: {
      loadMore() {
          this.$emit('loadMore')
      }
    }
}
</script>

<style lang="css" scoped>
 /* get max-width from theme setting */
.inner-wrap {  
  padding: 15px 0;
  margin: 0 auto;  
}

#search-result {
    padding-bottom:50px;
    background:#f7f7f7;
}

#search-result.ben-search .search-item.appended {
    margin-top:30px;
    opacity:0;
}

div#search-items {
    padding: 30px 0 0;
    text-align: center;
}

div#search-items:after {
    clear: both;
    display: block;
    content: '';
}

</style>