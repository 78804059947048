import FundraiserBoxRaises from './Fundraisers/FundraiserBoxRaises.vue'
import Fundraisers from './Fundraisers/Fundraisers.vue'

var AvailableComponents = [
    {
        name: 'fundraisers',
        component: Fundraisers
    },
    {
        name: 'fundraiserBoxRaises',
        component: FundraiserBoxRaises
    }
];

export default {
    LoadComponent: function(name) {
        return AvailableComponents.find(el => el.name.toLowerCase() === name.toLowerCase()).component;
    }    
}