<template>
  <div
    id="items"
    :class="[widgetLayout.containerClass]"
  >
    <div
      v-if="showTitle"
      :class="[widgetLayout.rowClass]"
    >
      <div class="heading-2 gf-frplb-title">
        {{ title }}
      </div>
    </div>
    <!-- infinite -->
    <div 
      v-infinite-scroll="loadMore"
      infinite-scroll-disabled="disableInfiniteScroll"
      infinite-scroll-distance="10"
      infinite-scroll-throttle-delay="200"
      infinite-scroll-immediate-check="false"
    >
      <component
        :is="widgetLayout.componentName"
        v-if="widgetLayout.componentName !== null"
        :items="items"
        :settings="settings"
      />
      <div v-else>
        <div
          v-for="(item, index) in items"
          :key="index"
          :class="[widgetLayout.rowClass, widgetLayout.pointerClass]"
          @click="openPage(item.url)"
        >
          <div
            :class="[
              'col-1',
              widgetLayout.borderClass,
              'p-2 m-0',
              widgetLayout.colIndex,
            ]"
          >
            <div class="rank">
              {{ index + 1 }}
            </div>
          </div>

          <div :class="['col-2', widgetLayout.borderClass, 'p-0 m-0 text-center']">
            <a :href="item.url">
              <img
                :src="item.imagePath"
                :alt="item.title"
                :class="[widgetLayout.imgClass]"
                :onerror="notAvailableImage"
              >
            </a>
          </div>
          <div
            :class="[
              'col-4',
              widgetLayout.borderClass,
              'p-2 m-0',
              widgetLayout.mainClass,
            ]"
          >
            <div :class="['row col', widgetLayout.nameClass]">
              {{ item.title }}
            </div>
            <div :class="['row col', widgetLayout.positionClass]">
              <Counters
                :sort-by="sortBy"
                :region-code="regionCode"
                :total-step-in-number="item.activityData.totalStepInNumber"
                :total-distance-in-meters="
                  item.activityData.totalDistanceInMeters
                "
                :total-activity-duration-in-seconds="
                  item.activityData.totalTimeInMinutes
                "
                :total-energy-in-calories="
                  item.activityData.totalEnergyInCalories
                "
                :total-value="item.total"
                :team-raised-total-value="item.hierarchyTotal"
                :currency-symbol="item.currencySymbol"
              />
            </div>
          </div>
          <div
            v-if="showEventName"
            :class="[
              widgetLayout.borderClass,
              'p-2 m-0 ',
              widgetLayout.positionClass,
              widgetLayout.colEventName,
              eventNameColClass,
            ]"
          >
            {{ item.eventName }}
          </div>
          <div
            v-if="showDonate"
            :class="[
              'col-2 text-center',
              widgetLayout.borderClass,
              'p-2 m-0',
              widgetLayout.positionClass,
            ]"
          >
            <a
              :class="[
                'btn btn-primary btn-etflb-donate-btn',
                widgetLayout.colDonate,
              ]"
              :href="item.donationUrl"
            >Donate</a>
          </div>
          <div
            :class="[
              'col-1',
              'gf-driver-background-bg gf-driver-button-color',
              widgetLayout.borderClass,
              'p-2 m-0',
              widgetLayout.positionClass,
              widgetLayout.colPosition,
            ]"
            :style="{
              background: item.driverButtonBgColour,
            }"
          >
            <div :class="[widgetLayout.innerPositionClass]">
              {{ index + 1 }}
            </div>
          </div>
        </div>
      </div>
      
      <loader
        :color="spinnerColour"
        :loading="showSpinner"
        size="36px"
      />

      <div
        v-if="showLoadMore && items.length > 0"
        class="loadMore"
      >
        <a
          id="showMoreIndividuals"
          class="btn gf-driver-button"
          @click="loadMore()"
        >
          Show More
        </a>
      </div>

      <div v-if="items.length == 0">
        There is nothing to show at the moment.
      </div>
    </div>
    <!-- END infinite-scroll -->
  </div>
</template>

<script>

import Counters from "../Counter/Counters.vue";
import Loader from "vue-spinner/src/ClipLoader.vue";
import widgetLayouts from "../../common/widgetLayouts.json";
import CustomTable from "./custom-table.vue";

export default {
    components: { Counters, Loader,  CustomTable},
    props: {
        title: {
        type: String,
        default: "Top Fundraisers",
		},
    showDonate: {
			type: Boolean,
			default: false,
		},
    showEventName: {
			type: Boolean,
			default: false,
		},
    widgetTemplate: {
			type: String,
			default: "",
		},
        showTitle: {
			type: Boolean,
			default: false,
		},
        sortBy: {
			type: String,
			default: ""
		},
        regionCode: {
			type: String,
			default: "",
		},
        spinnerColour: {
            type: String,
            default: "#000"
        },
        disableInfiniteScroll: {
            type:Boolean,
            default: false
        },
        items: {
            type: Array,
            default: () => []
        },
        showSpinner: {
            type: Boolean,
            default: true
        },
        showLoadMore: {
            type: Boolean,
            default: true
        },
        settings: {
          type: Object,
          default: () => {
          }
        }  
    },
    computed: {
      widgetLayout() {
        var layout = widgetLayouts.find(
          (w) => w.name === this.widgetTemplate
        );

        return layout || widgetLayouts[0];
		  },
      notAvailableImage() {
		    return `this.src='${require('@/assets/img/ImageNotAvailable.jpg')}'`;
		  },
      eventNameColClass() {
			  return this.showDonate ? "col-2" : "col-4";
		  }
    },

    methods: {
      loadMore() {
              this.$emit('loadMore')
      }
    }
}
</script>
<style scope lang="css" src="../../assets/css/EventsTopFundraisersLayout.css"></style>