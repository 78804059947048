<script>
import BeneficiaryParamsMixinVue from './Shared/BeneficiaryParamsMixin.vue'

export default {
  mixins: [BeneficiaryParamsMixinVue],
  props: {
    apiDomain: {
      type: String,
      default: "api.gofundraise.com.au"
    },
    cdnDomain: {
      type: String,
      default: "cdn.gofundraise.com.au"
    },
    title: {
      type: String,
      default: ""
    },
    regionCode: {
        type: String,
        default: ""
    },
    eventId: {
      type: String,
      default: ""
    },
    eventIds: {
      type: Array,
      default: () => []
    },
    pageType: {
      type: String,
      default: "S"
    },
    size: {
      type: Number,
      default: 12
    },
    sortBy: {
      type: String,
      default: "Total"
    },
    sortOrder: {
      type: String,
      default: "desc"
    },
    tags: {
      type: String,
      default: ""
    }
  }
}
</script>
