import { render, staticRenderFns } from "./Flip.vue?vue&type=template&id=283ca93e&"
import script from "./Flip.vue?vue&type=script&lang=js&"
export * from "./Flip.vue?vue&type=script&lang=js&"
import style0 from "./Flip.vue?vue&type=style&index=0&id=283ca93e&prod&lang=css&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports