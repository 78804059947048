var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{class:[_vm.widgetLayout.containerClass],attrs:{"id":"items"}},[(_vm.showTitle)?_c('div',{class:[_vm.widgetLayout.rowClass]},[_c('div',{staticClass:"heading-2 gf-frplb-title"},[_vm._v("\n      "+_vm._s(_vm.title)+"\n    ")])]):_vm._e(),_c('div',{directives:[{name:"infinite-scroll",rawName:"v-infinite-scroll",value:(_vm.loadMore),expression:"loadMore"}],attrs:{"infinite-scroll-disabled":"disableInfiniteScroll","infinite-scroll-distance":"10","infinite-scroll-throttle-delay":"200","infinite-scroll-immediate-check":"false"}},[(_vm.widgetLayout.componentName !== null)?_c(_vm.widgetLayout.componentName,{tag:"component",attrs:{"items":_vm.items,"settings":_vm.settings}}):_c('div',_vm._l((_vm.items),function(item,index){return _c('div',{key:index,class:[_vm.widgetLayout.rowClass, _vm.widgetLayout.pointerClass],on:{"click":function($event){return _vm.openPage(item.url)}}},[_c('div',{class:[
            'col-1',
            _vm.widgetLayout.borderClass,
            'p-2 m-0',
            _vm.widgetLayout.colIndex ]},[_c('div',{staticClass:"rank"},[_vm._v("\n            "+_vm._s(index + 1)+"\n          ")])]),_c('div',{class:['col-2', _vm.widgetLayout.borderClass, 'p-0 m-0 text-center']},[_c('a',{attrs:{"href":item.url}},[_c('img',{class:[_vm.widgetLayout.imgClass],attrs:{"src":item.imagePath,"alt":item.title,"onerror":_vm.notAvailableImage}})])]),_c('div',{class:[
            'col-4',
            _vm.widgetLayout.borderClass,
            'p-2 m-0',
            _vm.widgetLayout.mainClass ]},[_c('div',{class:['row col', _vm.widgetLayout.nameClass]},[_vm._v("\n            "+_vm._s(item.title)+"\n          ")]),_c('div',{class:['row col', _vm.widgetLayout.positionClass]},[_c('Counters',{attrs:{"sort-by":_vm.sortBy,"region-code":_vm.regionCode,"total-step-in-number":item.activityData.totalStepInNumber,"total-distance-in-meters":item.activityData.totalDistanceInMeters,"total-activity-duration-in-seconds":item.activityData.totalTimeInMinutes,"total-energy-in-calories":item.activityData.totalEnergyInCalories,"total-value":item.total,"team-raised-total-value":item.hierarchyTotal,"currency-symbol":item.currencySymbol}})],1)]),(_vm.showEventName)?_c('div',{class:[
            _vm.widgetLayout.borderClass,
            'p-2 m-0 ',
            _vm.widgetLayout.positionClass,
            _vm.widgetLayout.colEventName,
            _vm.eventNameColClass ]},[_vm._v("\n          "+_vm._s(item.eventName)+"\n        ")]):_vm._e(),(_vm.showDonate)?_c('div',{class:[
            'col-2 text-center',
            _vm.widgetLayout.borderClass,
            'p-2 m-0',
            _vm.widgetLayout.positionClass ]},[_c('a',{class:[
              'btn btn-primary btn-etflb-donate-btn',
              _vm.widgetLayout.colDonate ],attrs:{"href":item.donationUrl}},[_vm._v("Donate")])]):_vm._e(),_c('div',{class:[
            'col-1',
            'gf-driver-background-bg gf-driver-button-color',
            _vm.widgetLayout.borderClass,
            'p-2 m-0',
            _vm.widgetLayout.positionClass,
            _vm.widgetLayout.colPosition ],style:({
            background: item.driverButtonBgColour,
          })},[_c('div',{class:[_vm.widgetLayout.innerPositionClass]},[_vm._v("\n            "+_vm._s(index + 1)+"\n          ")])])])}),0),_c('loader',{attrs:{"color":_vm.spinnerColour,"loading":_vm.showSpinner,"size":"36px"}}),(_vm.showLoadMore && _vm.items.length > 0)?_c('div',{staticClass:"loadMore"},[_c('a',{staticClass:"btn gf-driver-button",attrs:{"id":"showMoreIndividuals"},on:{"click":function($event){return _vm.loadMore()}}},[_vm._v("\n        Show More\n      ")])]):_vm._e(),(_vm.items.length == 0)?_c('div',[_vm._v("\n      There is nothing to show at the moment.\n    ")]):_vm._e()],1)])}
var staticRenderFns = []

export { render, staticRenderFns }