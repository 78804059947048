<template lang="html">
  <div id="vue-gf-beneficiary-leaderboard">
    <div class="container">      
      <LeaderboardItems 
        :items="leaderboardItems"
        :title="title"
        :sort-by="pageSortBy"
        :widget-template="widgetTemplate"
        :region-code="pageRegionCode"
        :disable-infinite-scroll="disableInfiniteScroll"
        :show-donate="showDonate"
        :show-title="showTitle"
        :show-event-name="showEventName"
        :show-load-more="showLoadMore"
        :show-spinner="loading"
        @loadMore="lazyLoading"
      />
    </div>
  </div>
</template>

<script>
import 'promise-polyfill/src/polyfill';
import constants from '../../common/constants';
import LeaderboardItems from '../Shared/LeaderboardItems.vue'
import TemplatedLeaderboardVue from '../Shared/TemplatedLeaderboard.vue';

export default {
  name: 'BeneficiaryLeaderBoard',
  components: { LeaderboardItems },
  mixins: [TemplatedLeaderboardVue],

  data: function() {
    return {      
      beneficiaries: [],
      driverButtonColour: '',
      driverTextColour: '',
    };
  },

  computed: {
    leaderboardItems() {
      var vm = this;      
			return this.beneficiaries.map(i => {
				return {
					url: i.Url,
					donateUrl: i.BeneficiaryUrl,
					imagePath: i.BeneficiaryImagePath,
					title: i.BeneficiaryName,	
					eventName: '',				
					driverButtonBgColour: i.BeneficiaryTheme.DriverButtonBackgroundColour,
					activityData: {
						totalStepInNumber: null,
						totalDistanceInMeters: null,
						totalTimeInMinutes: null,
						totalEnergyInCalories: null
					},
					total: i.BeneficiaryTotal,
					hierarchyTotal: i.BeneficiaryHierarchy.BeneficiaryTotal
				}
			});
		}
  },

  mounted: function() {    
    this.populateLeaderBoard();
  },

  methods: {  
    async lazyLoading() {						
			if (!this.morePages) 
				return;

			this.loading= true;
      this.currentPage += 1;
			await this.populateLeaderBoard(this.currentPage);
		},

    async populateLeaderBoard() { 
      var params = {
        query: this.searchString,
        pagesize: this.size,
        pageindex: this.currentPage,
        sortorder: this.sortOrder,
        sortby: this.sortBy
      };
      
      if (this.eventId > 0) {
        params.query = null;
        params.eventcampaignid = this.eventId;
      }

      if (this.beneficiaryId && this.beneficiaryId > 0) {
        params.beneficiaryid = this.beneficiaryId;
        params.includesubbeneficiaries = true;
      }
      
      var data = await this.$gfApiServices.beneficiariesService.byParams(params)
      this.constructItems(data);
      this.morePages = this.isMoreDataAvailable(data);
      this.loading= false;
    },

    constructItems(data) {      
      var vm = this;
      data.Beneficiaries.forEach((elem, index)  => {
        var total = 0;
        switch (this.sortBy){
            case constants.EVENT_PAGE_SORT_BY_TOTAL:
              total = elem.BeneficiaryTotal;
              break;            
          }
          
          elem = Object.assign(elem, {
            "TotalRaw": total.toString()
          });

          vm.beneficiaries.push(elem);        
      });
     
    }
  }
}
</script>

<style lang="css" scoped>

</style>
