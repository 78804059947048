<!--  this component support lazyloading and only accept one single event-id value-->
<template lang="html">
  <div id="vue-gf-event-topfundraisers-leaderboard">
    <!--  Advanced Mode -->
    <div
      v-if="customTemplate"
      class="container"
    >
      <div
        v-infinite-scroll="fetchPageAndLoad"
        infinite-scroll-disabled="loading"
        infinite-scroll-distance="10"
        infinite-scroll-throttle-delay="200"
        infinite-scroll-immediate-check="false"
      >
        <div
          v-for="(item, index) in topFundraisersForAllEvents"
          :key="index"
          class="row pointer"
          @click="openPage(item.Url)"
        >
          <slot
            name="customTemplate"
            :page="item"
          >
            Custom Template here
          </slot>
        </div>
      </div>
    </div>

    <!-- Classic Mode -->
    <div v-if="!customTemplate">
      <LeaderboardItems
        :items="leaderboardItems"
        :title="title"
        :sort-by="pageSortBy"
        :widget-template="widgetTemplate"
        :region-code="pageRegionCode"
        :disable-infinite-scroll="disableInfiniteScroll"
        :show-donate="showDonate"
        :show-title="showTitle"
        :show-event-name="showEventName"
        :show-load-more="showLoadMore"
        :show-spinner="loading"
        :settings="settings"
        @loadMore="lazyLoading"
      />
    </div>
  </div>
</template>

<script>
import "promise-polyfill/src/polyfill";
import constants from "../common/constants";
import LeaderboardItems from "./Shared/LeaderboardItems.vue"
import TemplatedLeaderboard from "./Shared/TemplatedLeaderboard.vue"
import getEventsTopFundraisersData from "../data/getEventsTopFundraisersData.json";
import { currencyUtility } from "@gf/gf-utility";

export default {
	name: "EventsTopFundraisers",
	components: { LeaderboardItems },
	mixins: [TemplatedLeaderboard],

	data: function () {
		return {
			topFundraisersForAllEvents: []
		};
	},
	computed: {
		leaderboardItems() {
			return this.topFundraisersForAllEvents.map(i => {
				return {
					url: i.Url,
					donateUrl: i.DonateUrl,
					imagePath: i.ImagePath,
					title: i.LeaderboardTitle,
					eventName: i.Event.EventName,
					driverButtonBgColour: i.Event.EventTheme.DriverButtonBackgroundColour,
					activityData: {
						totalStepInNumber: i.ActivityData.TotalStepInNumber,
						totalDistanceInMeters: i.ActivityData.TotalDistanceInMeters,
						totalTimeInMinutes: i.ActivityData.TotalTimeInMinutes,
						totalEnergyInCalories: i.ActivityData.TotalEnergyInCalories
					},
					total: i.Total,
					hierarchyTotal: i.TeamRaisedTotal,
					currencySymbol: i.CurrencySymbol,
					creatorName:i.CreatorName,
					message:i.Message, 
					pageTitle: i.PageTitle
				}
			});
		},

		customTemplate() {
			return this.$scopedSlots.customTemplate;
		}
	},

	mounted() {
		this.$nextTick(async () => {
      await this.fetchPageAndLoad();
    })
	},

	methods: {
		async lazyLoading() {
			if (!this.morePages)
				return;

			this.loading= true;
			var results = await this.populateLeaderBoard(this.currentPage);

			results.Pages.forEach((o) => {
				this.topFundraisersForAllEvents.push(o);
			});

      		this.topFundraisersForAllEvents.concat(
				results.Pages
			);
			this.loading= false;
			this.morePages = this.isMoreDataAvailable(results);

			this.currentPage += 1;
		},
		async fetchPageAndLoad() {
			if (!this.morePages) {
				return;
			}

			this.loading = true;
			var results = await this.populateLeaderBoard(this.currentPage);

			results.Pages.forEach((o) => {
				this.topFundraisersForAllEvents.push(o);
			});

			this.topFundraisersForAllEvents.concat(results.Pages);
			this.loading = false;

			if(this.settings.loadMoreOption === constants.LOAD_MORE_OPTION_FIXED) {
				this.morePages = false;
			}
			else {
				this.morePages = this.isMoreDataAvailable(results);
				this.currentPage += 1;
			}
		},


		async populateLeaderBoard(pageIndex) {

			if (this.isDemoMode) {
				var chunkSize = this.size;
				if(chunkSize >20) chunkSize = 20;

				var data =Object.assign({}, getEventsTopFundraisersData);
				data.Pages = data.Pages.slice(0, chunkSize);
        		return this.constructTopFundraisers(data);
			}

			if(this.settings.loadMoreOption === constants.LOAD_MORE_OPTION_FIXED && this.size > this.maxSize)
				this.size = this.maxSize;

				var leaderboardMode = this.pageType === constants.FUNDRAISING_PAGE_INDIVIDUAL_TYPE_ID
						? constants.FUNDRAISING_PAGE_INDIVIDUAL_TYPE_ID.toLowerCase()
						: constants.FUNDRAISING_PAGE_TEAM_TYPE_ID.toLowerCase();

				var res = null
				var resCurrency = null
				var eventIds = this.eventIds || [this.eventId] || []
				var topFundraisers = {
					Pages: [],
							ResultsReturned: 0,
							TotalPages: 0,
							PageNumber: 0,
				}
				var topFundraisersContructed = topFundraisers				
				var params = {
					eventcampaignid: this.eventId,
					pagetype: leaderboardMode,
					pagesize: this.size,
					pageindex: pageIndex,
					sortorder: this.pageSortOrder,
					sortby: this.pageSortBy,
					tags: this.tags,
					includesubevents: this.settings.includeSubEvents,
					includesubbeneficiaries: this.includeSubBeneficiaries,
					includeparentbeneficiary: this.includeParentBeneficiary,
					includeteammembers: this.settings.includeTeamMembers,
					pageid: this.settings.pageid
				};
				if (this.beneficiaryId && this.beneficiaryId.length > 0)
					params.beneficiaryaccountid = this.beneficiaryId;

                if (this.excludeDisabledFundraisingPages) {
                    params.excludepageswithnofundraising = (this.excludeDisabledFundraisingPages == 'true')
                }

				if (eventIds.length > 0) {
					for (var evtKey in eventIds) {
						var eventId = eventIds[evtKey]
						params.eventcampaignid = eventId

						res = await this.$gfApiServices.pagesService.byParams(params)					
						resCurrency = await currencyUtility.default.getCurrencyInfo(this.apiDomain, eventId)
						
						topFundraisersContructed = this.constructTopFundraisers(res, resCurrency);

						topFundraisers.Pages.push(...topFundraisersContructed.Pages)
						topFundraisers.ResultsReturned += topFundraisersContructed.ResultsReturned
						topFundraisers.TotalPages += topFundraisersContructed.TotalPages
						topFundraisers.PageNumber = pageIndex;
					}					
					this.$gfLogger.logInfo('topFundraisers > Multiple event ids', topFundraisers)
					return topFundraisers
				}
				
				res = await this.$gfApiServices.pagesService.byParams(params)
				resCurrency = await currencyUtility.default.getCurrencyInfo(this.apiDomain, this.eventId)
				return this.constructTopFundraisers(res, resCurrency);
		},

		constructTopFundraisers(data, resultCurrency) {
			var topFundraisers = [];

			data.Pages.forEach((elem, index) => {
				var teamRaisedTotal =
					elem.PageType == constants.FUNDRAISING_PAGE_TEAM_TYPE_ID
						? elem.Team.TeamTotal
						: 0;
				var total = 0;

				switch (this.pageSortBy) {
					case constants.FUNDRAISING_PAGE_SORT_BY_TOTAL:
						total = elem.Total;
						break;
					case constants.FUNDRAISING_PAGE_SORT_BY_TEAM_TOTAL:
						total = teamRaisedTotal;
						break;
					case constants.FUNDRAISING_PAGE_SORT_BY_TOTAL_STEP:
						total = elem.ActivityData.TotalStepInNumber;
						break;
					case constants.FUNDRAISING_PAGE_SORT_BY_TOTAL_DISTANCE:
						total = elem.ActivityData.TotalDistanceInMeters;
						break;
					case constants.FUNDRAISING_PAGE_SORT_BY_TOTAL_TIME:
						total = elem.ActivityData.TotalTimeInMinutes;
						break;
					case constants.FUNDRAISING_PAGE_SORT_BY_TOTAL_ENERGY:
						total = elem.ActivityData.TotalEnergyInCalories;
						break;
				}

				elem = Object.assign(elem, {
					TotalRaw: total,
					TeamRaisedTotal: teamRaisedTotal.toString(),
					LeaderboardTitle:
						elem.PageType == constants.FUNDRAISING_PAGE_TEAM_TYPE_ID
							? elem.PageTitle
							: elem.CreatorName,
					CurrencySymbol: resultCurrency != null && typeof resultCurrency.symbol != 'undefined' ? 
							resultCurrency.symbol : 
							typeof elem.defaults.currency.symbol != 'undefined' ? elem.defaults.currency.symbol : '$'
				});
				topFundraisers.push(elem);
			});

			return {
				Pages: topFundraisers,
				ResultsReturned: data.ResultsReturned,
				TotalPages: data.TotalPages,
				PageNumber: data.PageNumber,
			};
		},

		openPage(pageUrl) {
			window.location = pageUrl;
		},
	},
};
</script>