<template lang="html">
  <div
    v-if="ready"
    id="vue-gf-frp-leaderboard"
  >
    <template v-if="widgetType === 'TopFundraisers'">
      <TopFundraisers
        :api-domain="apiDomain"
        :title="title"
        :event-id="eventId"
        :event-ids="eventIds"
        :beneficiary-id="beneficiaryId"
        :page-type="pageType"
        :size="size"
        :block-background-color="blockBackgroundColor"
        :block-text-color="blockTextColor"
        :tags="tags"
        :sort-by="sortBy"
        :sort-order="pageSortOrder"
      />
    </template>

    <template v-if="widgetType === 'FundraiserSearch'">
      <FundraiserSearch
        :api-domain="apiDomain"
        :title="title"
        :event-id="eventId"
        :beneficiary-id="beneficiaryId"
        :region-code="pageRegionCode"
        :page-type="pageType"
        :size="size"
        :block-background-color="blockBackgroundColor"
        :block-text-color="blockTextColor"
        :tags="tags"
        :settings="settings"
        :is-demo-mode="isDemoMode"
      />
    </template>

    <template v-if="widgetType === 'EventsTopFundraisers'">     
      <EventsTopFundraisersLegacy        
        v-if="isEventsTopFundraisersLegacy"
        :api-domain="apiDomain"
        :cdn-domain="cdnDomain"
        :title="title"
        :event-id="eventId"
        :event-ids="eventIds"
        :page-type="pageType"
        :tags="tags"
        :show-donate="showDonate"
        :show-event-name="showEventName"
        :size="size"
        :page-sort-by="sortBy"
        :page-sort-order="pageSortOrder"
        :page-region-code="pageRegionCode"
        :show-title="showTitle"
      >
        <template
          v-for="(index, name) in $scopedSlots"
          v-slot:[name]="data"
        >
          <slot
            :name="name"
            v-bind="data"
          />
        </template>
      </EventsTopFundraisersLegacy>

      <EventsTopFundraisers
        v-else
        :api-domain="apiDomain"
        :cdn-domain="cdnDomain"
        :title="title"
        :event-id="eventId"        
        :event-ids="eventIds"
        :page-type="pageType"
        :tags="tags"
        :show-donate="showDonate"
        :show-event-name="showEventName"
        :size="size"
        :page-sort-by="sortBy"
        :page-sort-order="pageSortOrder"
        :page-region-code="pageRegionCode"
        :widget-template="widgetTemplate"
        :beneficiary-id="beneficiaryId"
        :include-sub-beneficiaries="includeSubBeneficiaries"
        :include-parent-beneficiary="includeParentBeneficiary"
        :settings="settings"
        :show-title="showTitle"
        :is-demo-mode="isDemoMode"
        :exclude-disabled-fundraising-pages="excludeDisabledFundraisingPages"
      >
        <template
          v-for="(index, name) in $scopedSlots"
          v-slot:[name]="data"
        >
          <slot
            :name="name"
            v-bind="data"
          />
        </template>
      </EventsTopFundraisers>
    </template>

    <template v-if="widgetType === 'EventsLeaderBoard'">
      <EventsLeaderBoard
        :api-domain="apiDomain"
        :title="title"
        :search-string="searchString"
        :event-id="eventIds.length > 0 ? eventIds[0] : 0"
        :page-type="pageType"
        :size="size"
        :tags="tags"
        :page-sort-by="sortBy"
        :page-sort-order="pageSortOrder"
        :page-region-code="pageRegionCode"
      />
    </template>
    <template v-if="widgetType === 'FundraiserEventStats'">
      <FundraiserEventStats
        :api-domain="apiDomain"
        :event-ids="eventIds"
        :settings="settings"
        :tags="tags"
      />
    </template>
    <template v-if="widgetType === 'BeneficiaryLeaderBoard'">
      <BeneficiaryLeaderBoard
        :api-domain="apiDomain"
        :cdn-domain="cdnDomain"
        :title="title"
        :event-id="eventId"
        :event-ids="eventIds"
        :page-type="pageType"
        :tags="tags"
        :show-donate="showDonate"
        :show-event-name="showEventName"
        :size="size"
        :page-sort-by="sortBy"
        :page-sort-order="pageSortOrder"
        :page-region-code="pageRegionCode"
        :widget-template="widgetTemplate"
        :beneficiary-id="beneficiaryId"
        :include-sub-beneficiaries="includeSubBeneficiaries"
        :include-parent-beneficiary="includeParentBeneficiary"
        :settings="settings"
        :show-title="showTitle"
      />
    </template>
  </div>
</template>

<script>
import Vue from "vue";
import BeneficiaryLeaderBoard from "./components/BeneficiaryLeaderboard/BeneficiaryLeaderBoard.vue"
import TopFundraisers from "./components/TopFundraisers.vue";
import FundraiserSearch from "./components/FundraiserSearch.vue";
import EventsTopFundraisersLegacy from "./components/EventsTopFundraisersLegacy.vue";
import EventsTopFundraisers from "./components/EventsTopFundraisers.vue";
import EventsLeaderBoard from "./components/EventsLeaderBoard.vue";
import FundraiserEventStats from "./components/FundraiserEventStats.vue";
import constants from "./common/constants";
import infiniteScroll from "vue-infinite-scroll";
import gfApiServices from "@gf/gf-api-services"
import { VueLoggerPlugin } from "@gf/gf-logger";
import BeneficiaryParamsMixinVue from './components/Shared/BeneficiaryParamsMixin.vue';
import { currencyUtility } from "@gf/gf-utility"
import store from './common/store'

Vue.use(infiniteScroll);
Vue.use(VueLoggerPlugin);

export default {
    name: "GfFrpLeaderboard",

    components: {
        BeneficiaryLeaderBoard,
        TopFundraisers,
        FundraiserSearch,
        EventsTopFundraisers,
        EventsTopFundraisersLegacy,
        EventsLeaderBoard,
        FundraiserEventStats
    },
    
    mixins: [BeneficiaryParamsMixinVue],

    props: {
        /**
         * Available Types - "TopFundraisers", "EventsTopFundraisers", "EventsLeaderBoard", "BeneficiaryLeaderBoard"
         */
        widgetType: {
            type: String,
            default: "TopFundraisers"
        },
        title: {
            type: String,
            default: ""
        },
        apiDomain: {
            type: String,
            default: "api.gofundraise.com.au"
        },
        cdnDomain: {
            type: String,
            default: "cdn.gofundraise.com.au"
        },
        eventId: {
            type: String,
            default: ""
        },
        eventIds: {
            type: Array,
            default: () => []
        },
        /*
         * For Individual fundraiisng pages, use "S". For team fundraising pages use "T"
         */
        pageType: {
            type: String,
            default: "S"
        },
        /*
         * list of tags that we want to query on as a CSV
         */
        tags: {
            type: String,
            default: ""
        },
        /*
         * Search string when querying events by name rather than Ids. If event ids are provided, search string is not used.
         */
        searchString: {
            type: String,
            default: ""
        },
        /*
         * Shows the donate button on the widgets that supports it
         */
        showDonate: {
            type: Boolean,
            default: false
        },
        /*
         * Shows the event name on the widgets that supports it
         */
        showEventName: {
            type: Boolean,
            default: false
        },
        settings: {
            type: Object,
            default: () => {}
        },
        size: {
            type: Number,
            default: 12
        },
        blockBackgroundColor: {
            type: String,
            default: ""
        },
        blockTextColor: {
            type: String,
            default: ""
        },
        pageSortBy: {
          type: String,
          default: ""
        },
        pageSortOrder: {
          type: String,
          default: "desc"
        },
        pageRegionCode: {
            type: String,
            default: ""
        },
        widgetTemplate: {
          type: String,
          default: ""
       },
       showTitle:{
          type: Boolean,
          default: false
       },
       isDemoMode:{
          type: Boolean,
          default: false
       },
       excludeDisabledFundraisingPages: {
          type: String,
          default: '',
       },
    },

    data() {
        return {
            ready: false,
            sortBy: "",
            isEventsTopFundraisersLegacy: true,
            store,
            gfApiServices,
        }
    },

    created() {
      Vue.use(this.gfApiServices.GfApiServicesPlugin, { logger: this.$gfLogger, apiDomain: this.apiDomain});
    },

    async mounted() {     
        this.isEventsTopFundraisersLegacy = this.widgetTemplate === "";

        if (this.pageSortBy === "") {
          this.sortBy = this.pageType.toLowerCase() === constants.FUNDRAISING_PAGE_INDIVIDUAL_TYPE_ID.toLowerCase()
            ? constants.FUNDRAISING_PAGE_SORT_BY_TOTAL
            : constants.FUNDRAISING_PAGE_SORT_BY_TEAM_TOTAL;
        } else {
            this.sortBy = this.pageSortBy;
        }
        this.ready = true;
        
        var resCurrency = await currencyUtility.default.getCurrencyInfoByRegion(this.apiDomain, this.pageRegionCode)
        if (typeof resCurrency.symbol != 'undefined') {
          this.store.setCurrencySymbol(resCurrency.symbol)
        }
    }
};
</script>

<style lang="css" scoped>
@import "../node_modules/bootstrap/dist/css/bootstrap-grid.min.css";
@import "../node_modules/bootstrap/dist/css/bootstrap.min.css";
</style>
