<template>
  <span>{{ val }} {{ measurementUnit }}</span>
</template>

<script>
export default{
    name: "Counter",
    props: {
        val: {
            type: String,
            default: ""
        },
        measurementUnit: {
            type: String,
            default: ""
        }
    }
}
</script>
<style scoped>

</style>