<template>
  <div
    v-if="fundraiserSearchResults"
    class="row"
  >
    <div
      v-for="(page, index) of fundraiserSearchResults" 
      :id="index"
      :key="index" 
      class="gf-frp-search-item pointer col-sm-12 col-md-6 my-2"
      @click="onItemClicked(page)"
    >          
      <Fundraiser :page="page" />
    </div>
  </div> 
</template>

<script>
import Fundraiser from './Fundraiser.vue'

export default {
    components: {Fundraiser},
    props:{
        fundraiserSearchResults: {
            type: Array,
            default: () => []
        },
    },
    methods: {
      onItemClicked(page) {
        window.location = page.pageUrl;
      }
    }

}
</script>