<template>
  <table class="table table-striped">
    <thead>
      <tr>
        <th
          v-for="(item, index) in settings.tableHeaders"
          :key="index"
          v-html="item"
        />
      </tr>
    </thead>
    <tbody>
      <tr
        v-for="(item, index) in items"
        :key="index"
      >
        <td
          v-for="(props, i) in settings.tableDataProperties"
          :key="i"
          v-html="item[props]"
        />
      </tr>
    </tbody>
  </table>
</template>
  
  <script>
  
  export default {
      name: "CustomTable",   
      props: {
        items: {
            type: Array,
            default: () => []
        },
        settings: {
			type: Object,
			default: () => {
				
			}
		}  
      }
  }
  </script>