<template lang="html">
  <div class="row">
    <span v-if="isEmpty" />

    <div v-if="showTotal">
      <Counter
        class="gf-counters-total"
        :val="total"
      />
    </div>

    <div v-if="showTeamRaisedTotal">
      <Counter
        class="gf-counters-teamtotal"
        :val="teamRaisedTotal"
      />
    </div>

    <div v-if="showDistance">
      <Counter
        class="gf-counters-distance"
        :val="distance.val"
        :measurement-unit="distance.unit"
      />
    </div>

    <div
      v-if="showSteps"
    >
      <Counter
        class="gf-counters-steps"
        :val="steps"
      />
    </div>

    <div
      v-if="showCalories"
    >
      <Counter
        class="gf-counters-energy"
        :val="calories.val"
        :measurement-unit="calories.unit"
      />
    </div>
    <div v-if="showTime">
      <Counter
        class="gf-counters-time"
        :val="time.val"
        :measurement-unit="time.unit"
      />
    </div>
  </div>
</template>

<script>
import Counter from './Counter'
import constants from '../../common/constants';
import Converters from '../Converters.vue'

export default {
    name: "Counters",
    components: { Counter },
    mixins: [Converters],
    props:{
        sortBy: {
          type: String,
          default: 'TotalStep'
        },
        regionCode: {
            type: String,
            default: "AU"
        },
        totalDistanceInMeters: {
            type: String ,
            default: ''
        },
        totalStepInNumber: {
            type: String,
            default: ''
        },
        totalActivityDurationInSeconds: {
            type: String,
            default: ''
        },
        totalEnergyInCalories: {
            type: String,
            default: ''
        },
        totalValue: {
            type: String,
            default: ''
        },
        teamRaisedTotalValue: {
            type: String,
            default: ''
        },
        currencySymbol:{
            type: String,
            default: ''
        }
    },
    computed: {
         isEmpty() {
            var hasValues =
                this.showDistance ||
                this.showSteps ||
                this.showCalories ||
                this.showTime ||
                this.showTotal ||
                this.showTeamRaisedTotal
                ;
            return hasValues;
        },

        total() {
            return this.currencySymbol + this.$_Converters_ToCurrencyForm(this.totalValue);
        },
        showTotal() {
            return this.sortBy === constants.FUNDRAISING_PAGE_SORT_BY_TOTAL;
        },

        teamRaisedTotal() {
            return this.currencySymbol + this.$_Converters_ToCurrencyForm(this.teamRaisedTotalValue);
        },
        showTeamRaisedTotal() {
            return this.sortBy === constants.FUNDRAISING_PAGE_SORT_BY_TEAM_TOTAL;
        },

        distance() {
            if (this.regionCode == "US") {
                var valInMiles =
                    parseInt(
                        this.totalDistanceInMeters
                    ) * 0.000621371;
                return {
                    val: (valInMiles < 1
                        ? valInMiles * 5280
                        : valInMiles
                    ).toFixed(2).toString(),
                    unit: valInMiles < 1 ? "ft" : "mi"
                };
            }
            var valInKm =
                parseInt(
                    this.totalDistanceInMeters
                ) / 1000;
            return {
                val: (valInKm < 1 ? (valInKm * 1000).toFixed(0) : valInKm.toFixed(2)).toString(),
                unit: valInKm < 1 ? "m" : "km"
            };
        },

        showDistance() {
            return parseInt(this.distance.val) > 0 && this.sortBy === constants.FUNDRAISING_PAGE_SORT_BY_TOTAL_DISTANCE;
        },

        steps() {
            if (
                isNaN(
                    parseInt(this.totalStepInNumber)
                )
            )
            return "NA";

            return parseInt(this.totalStepInNumber).toString();
        },

        showSteps() {
            return !(this.steps === "NA" || parseInt(this.steps) <= 0) && this.sortBy === constants.FUNDRAISING_PAGE_SORT_BY_TOTAL_STEP;
        },

        calories() {
            if (
                isNaN(
                    parseInt(
                        this.totalEnergyInCalories
                    )
                )
            )
            return { val: "NA", unit: "kCal" };

            var valInKiloCalories =
                this.totalEnergyInCalories / 1000;
            return {
                val:
                    (valInKiloCalories < 1
                        ? (valInKiloCalories * 1000).toFixed(0)
                        : parseFloat(valInKiloCalories).toFixed(2)).toString(),
                unit: valInKiloCalories < 1 ? "cal" : "kCal"
            };
        },

        showCalories() {
            return (!(this.calories.val === "NA" || parseInt(this.calories.val) <= 0)) && this.sortBy === constants.FUNDRAISING_PAGE_SORT_BY_TOTAL_ENERGY;
        },

        time() {
            if (
                isNaN(
                    parseInt(
                        this.totalActivityDurationInSeconds
                    )
                )
            )
            return { val: "NA", unit: "hrs" };

            var valInHours =
                this.totalActivityDurationInSeconds / 3600;
            return {
                val:
                    (valInHours < 3
                        ? valInHours * 60
                        : parseFloat(valInHours)).toFixed(0).toString(),
                unit: valInHours < 3 ? "mins" : "hrs"
            };
        },

        showTime() {
            return !(this.time.val === "NA" || parseInt(this.time.val) <= 0) && this.sortBy === constants.FUNDRAISING_PAGE_SORT_BY_TOTAL_TIME;
        }
    }
}

</script>

<style>

</style>