<script>
export default {
  methods: {
    $_Converters_ToCurrencyForm(val) {
      if (isNaN(parseInt(val)))
            return 0;
      const fixed = 2;
      return parseFloat(val).toFixed(fixed).replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,');
    }
  }
}
</script>
