<template>
  <div class="search-item individual col-lg-3 col-md-4 col-sm-6 col-xs-6 transition">         
    <div      
      :class="['search-item-wrap transition', itemClass]"
      @click="onItemClicked(page)"
    >
      <div class="search-item-image">
        <div
          class="event-banner"          
          :style="{ backgroundImage: 'url('+ page.eventImageUrl +')' }"
        >
          <div
            class="event-banner-overlay"            
            :style="{ background: page.secondaryBgColor }"
          />
        </div>
        <a
          class="item-image"
          :style="{ backgroundImage: 'url('+ encodeURI(page.imageUrl)+')' }"     
          :href="page.Url"
        ><h3 :title="page.title">{{ page.title }}</h3></a>
      </div>
      <div class="search-item-desc">
        <p class="eventname">
          {{ page.eventName }}
        </p>
        <p class="total">
          {{ getCurrencySymbol }}{{ raised }}
        </p>
        <div class="search-item-btns">
          <a
            :href="page.donateUrl"
            class="gf-driver-button search-item-donate transition search-item-btn"
          ><span class="fa fa-heart" />Donate</a>
         
          <a
            v-if="!hideJoinTeam"
            :class="{ hideJoinTeam: !isTeam }"                       
            :href="teamUrl"
            class="gf-secondary-button gf-secondary-border jointeam search-item-fundraise transition search-item-btn"
          >Join Team</a>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import constants from '../../common/constants';
import store from '../../common/store'

export default {
    props:{
        page: {
            type: Object,
            default: () => {}
        },
        hideJoinTeam: {
            type: Boolean,
            default: false
        }
    },

    data() {
      return {
        store,
      }
    },

    computed: {
        teamUrl(){            
            return `${this.page.eventHomeUrl}/pages/create?tid=${this.page.id}`;
        },
        isTeam(){
            return this.page.pageType === constants.FUNDRAISING_PAGE_TEAM_TYPE_ID;
        },
        raised() {
          return parseFloat(this.page.raised).toFixed(2).replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,')
        },
        itemClass(){
          return this.hideJoinTeam? 'search-item-wrap-no-join-team' : '';
        },
        getCurrencySymbol() {
          return this.store.state.currencySymbol
        },
    },
    
    methods: {
      onItemClicked(page) {
        window.location = page.pageUrl;
      }
    }
}
</script>

<style scoped>

.hideJoinTeam {
    visibility: hidden;
}

.search-item-wrap {
    min-height: 320px;
}

.search-item-wrap-no-join-team {
    min-height: 280px !important;
}

.search-item-image {
    background: #fff;
}

.search-item-desc h3 {
    margin-bottom: 10px;
    font-weight: bold;
    margin: 25px auto;
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
    width: 80%;
}

.search-item {
    padding: 10px;
    float: left;
}

.search-item-btns a.search-item-fundraise {
    background: #1a3a49;
    color: #fff;
}

.search-item-btns a span.fa {
    margin-right: 7px;
}

.search-item-btns a.search-item-donate {
    background: #f47d32;
    color: #fff;
}

.search-item-btns a {
    text-decoration: none;
    background: #f7f7f7;
    display: block;
    padding: 7px;
    width: 80%;
    margin: 10px auto;
    border-radius: 50px;
    font-size: 14px;
}

.search-item-btns {
    margin-bottom: 20px;
}

.search-item-wrap {
    border-radius: 5px;
    overflow: hidden;
    cursor:pointer;
    background:#fff;
}

.search-item.appended {
    margin-top: 30px;
    opacity: 0;
}

.search-item-btns .team-buttons a {
    display: inline-block;
    width: 40%;
    margin: 0;
    font-size: 13px;
}

.search-item-wrap {
    min-height: 320px;
}

.search-item-btns a.secondarybtn {
    background: #1a3a49;
    color: #fff;
}

.search-item-btns a.primarybtn {
    background: #f47d32;
    color: #fff;
}

.search-item-btns a.lnkJoinTeam {
    color: #fff;
    background-image: none;
    background: none;
    background-color: #1a3a49;
    font-size: 14px !important;    
    border: 0;
    line-height: 14px !important;
    padding: 10px !important;
}

.search-item-btns a.lnkJoinTeam > span {
    background: none;
    color: #fff;    
    font-weight: normal;
    font-size: 14px !important;
    padding: 0;
    line-height: 14px !important;
    height: auto !important;
}

.search-item-desc h3 {
    margin-bottom: 10px;
    font-weight: bold;
    margin: 15px auto 5px;
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
    width: 80%;
}

.search-item p.eventname {
    width: 80%;
    margin: 0 auto 5px;
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
}

.search-item p.total {
    font-weight: bold;
}

.search-item a.lnkJoinTeam.button-gray.gray {
    display: none;
}

.search-item-image .item-image {
    position: relative;
}

.search-item-image .item-image h3 {    
    background: rgba(0,0,0,0.4);
    color: #fff;
    font-size: 14px;
    padding: 5px 15px;
    position: absolute;
    width: 100%;
    bottom: 20px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
}

.item-image {
    background-repeat: no-repeat;
    height: 120px;
    width: 120px;
    background-size: cover;
    background-position: center center;
    margin: -60px auto 15px;
    border-radius: 500px;
    background-color: #ddd;
    display: block;
    overflow: hidden;
}

#search-result.ben-search .item-image {
    background-size: contain;
    width: 85%;
    height: 120px;
    margin: 20px auto;
    border-radius: 0;
    background-color: transparent;
}

.event-banner {
    background-color: #e1e1e1;
    height: 80px;
    width: 100%;
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center center;
    overflow: hidden;
    position: relative;
    opacity: 0.7;
}

.event-banner-overlay {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    opacity: 0.8;
    background: #f47d32;
}
</style>