<!--  this component is support loading data by multiple event-ids, we keep this version to support legacy wiget -->

<template lang="html">
  <div id="vue-gf-event-topfundraisers-leaderboard">
    <!--  Advanced Mode -->
    <div
      v-if="customTemplate"
      class="container"
    >
      <div
        v-for="(item, index) in topFundraisersForAllEvents"
        :key="index"
        class="row pointer"
        @click="openPage(item.Url)"
      >
        <slot
          name="customTemplate"
          :page="item"
        >
          Custom Template here
        </slot>
      </div>
    </div>

    <!-- Classic Mode -->
    <div
      v-if="!customTemplate"
      class="container"
    >
      <div class="row mb-2">
        <div class="h1 gf-frplb-title">
          {{ title }}
        </div>
      </div>
      <div
        v-for="(item, index) in topFundraisersForAllEvents"
        :key="index"
        class="row pointer"
        @click="openPage(item.Url)"
      >
        <div
          class="col-2 border p-0 m-0 text-center"
        >
          <a :href="item.Url">
            <img
              :src="item.ImagePath"
              :alt="item.PageTitle"
              class="gf-etflb-img"
              :onerror="notAvailableImage"
            >
          </a>
        </div>
        <div class="col-4 border p-2 m-0 gf-etflb-main">
          <div class="row col gf-etflb-name">
            {{ item.LeaderboardTitle }}
          </div>
          <div class="row col gf-etflb-position">            
            <Counters
              :sort-by="pageSortBy"
              :region-code="pageRegionCode"
              :total-step-in-number="item.ActivityData.TotalStepInNumber"
              :total-distance-in-meters="item.ActivityData.TotalDistanceInMeters"
              :total-activity-duration-in-seconds="item.ActivityData.TotalTimeInMinutes"
              :total-energy-in-calories="item.ActivityData.TotalEnergyInCalories"
              :total-value="item.Total"
              :team-raised-total-value="item.TeamRaisedTotal"
              :currency-symbol="item.CurrencySymbol"
            /> 
          </div>
        </div>
        <div
          v-if="showEventName"
          :class="{'col-2': showDonate, 'col-4': !showDonate}"
          class="border p-2 m-0 gf-etflb-position"
        >
          {{ item.Event.EventName }}
        </div>
        <div
          v-if="showDonate"
          class="col-2 text-center border p-2 m-0 gf-etflb-position"
        >
          <a
            class="btn btn-primary btn-etflb-donate-btn"
            :href="item.DonationUrl"
          >Donate</a>
        </div>
        <div class="col-1 border p-2 m-0 gf-etflb-position">
          {{ item.LeaderboardPosition }}
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import axios from "axios";
import "promise-polyfill/src/polyfill";
import constants from '../common/constants';
import Base from './Base.vue';
import Counters from './Counter/Counters.vue'
import { currencyUtility } from "@gf/gf-utility"

export default {
  name: "EventsTopFundraisersLegacy",
  components: { Counters },
  mixins: [Base],

  props: {
    title: {
      type: String,
      default: "Top Fundraisers"
    },
    showDonate: {
      type: Boolean,
      default: false
    },
    showEventName: {
      type: Boolean,
      default: false
    },
    pageSortBy: {
      type: String,
      default: "",
      validator(value) {
        const isValid = [
          "",
          constants.FUNDRAISING_PAGE_SORT_BY_TOTAL_STEP,
          constants.FUNDRAISING_PAGE_SORT_BY_TOTAL_DISTANCE,
          constants.FUNDRAISING_PAGE_SORT_BY_TOTAL_TIME,
          constants.FUNDRAISING_PAGE_SORT_BY_TOTAL_ENERGY,
          constants.FUNDRAISING_PAGE_SORT_BY_TEAM_TOTAL,
          constants.FUNDRAISING_PAGE_SORT_BY_TOTAL
        ].includes(value);
        return isValid;
      }
    },
    pageSortOrder: {
      type: String,
      default: "desc"
    },
    pageRegionCode: {
        type: String,
        default: ""
    },
  },

  data: function() {
    return {
      topFundraisersForAllEvents: [],
      driverButtonColour: "",
      driverTextColour: ""
    };
  },
  computed: {
    customTemplate() {
      return this.$scopedSlots.customTemplate;
    },
    notAvailableImage() {
		  return `this.src='${require('@/assets/img/ImageNotAvailable.jpg')}'`;
		}
  },

  mounted() {    
    this.populateLeaderBoard();
  },

  methods: {
    async populateLeaderBoard() {
      var eventLeaderBoardPromises = [];
      var eventCurrencies = [];
      for(const eventId of this.eventIds){
         var resCurrency = await currencyUtility.default.getCurrencyInfo(this.apiDomain, eventId)
         eventCurrencies.push({'eventId' :eventId.toString(), 'currency' : resCurrency});
      }
      
      this.eventIds.forEach(element => {       
        eventLeaderBoardPromises.push(
          this.getLeaderBoardForEvent(element, this.pageType, this.size, this.pageSortBy, this.pageSortOrder)
        );
      });
     
      await axios.all(eventLeaderBoardPromises).then(res => {
        res.forEach(response => {
          if (response.status === 200) {
            this.constructTopFundraisers(response.data, eventCurrencies);
          }
        });
      });

      this.sortAndSliceLeaderboardToSize();
    },

    getLeaderBoardForEvent(eventId, pageType, size, pageSortBy, pageSortOrder) {
      var leaderboardMode = pageType === constants.FUNDRAISING_PAGE_INDIVIDUAL_TYPE_ID ? constants.FUNDRAISING_PAGE_INDIVIDUAL_TYPE_ID.toLowerCase() : constants.FUNDRAISING_PAGE_TEAM_TYPE_ID.toLowerCase();
      var leaderboardUrl = `https://${this.apiDomain}/v1/pages/search?eventcampaignid=${eventId}&pagetype=${leaderboardMode}&pagesize=${size}&sortorder=${pageSortOrder}&sortby=${pageSortBy}&tags=${this.tags}`;
      return axios.get(leaderboardUrl);
    },

    constructTopFundraisers(data, eventCurrencies) {
      var vm = this;
      data.Pages.forEach((elem, index) => {
        if (index < vm.size) {
          var teamRaisedTotal = elem.PageType == constants.FUNDRAISING_PAGE_TEAM_TYPE_ID ? elem.Team.TeamTotal : 0;
          
          var total = 0;
          switch (this.pageSortBy){
            case constants.FUNDRAISING_PAGE_SORT_BY_TOTAL:
              total = elem.Total;
              break;
            case constants.FUNDRAISING_PAGE_SORT_BY_TEAM_TOTAL:
              total = teamRaisedTotal;
              break;  
            case constants.FUNDRAISING_PAGE_SORT_BY_TOTAL_STEP:             
              total = elem.ActivityData.TotalStepInNumber;
              break;  
            case constants.FUNDRAISING_PAGE_SORT_BY_TOTAL_DISTANCE:
              total = elem.ActivityData.TotalDistanceInMeters;
              break;  
            case constants.FUNDRAISING_PAGE_SORT_BY_TOTAL_TIME:
              total = elem.ActivityData.TotalTimeInMinutes;
              break;  
            case constants.FUNDRAISING_PAGE_SORT_BY_TOTAL_ENERGY:
              total = elem.ActivityData.TotalEnergyInCalories;
              break;
          }
          
          var eventCurrency = eventCurrencies.find(c => c.eventId === elem.Event.EventCampaignId);
          var resultCurrency = null
          if (eventCurrency) resultCurrency = eventCurrency.currency

          elem = Object.assign(elem, {
            "TotalRaw": total,     
            "TeamRaisedTotal": teamRaisedTotal.toString(),
            "LeaderboardTitle": elem.PageType == constants.FUNDRAISING_PAGE_TEAM_TYPE_ID ? elem.PageTitle : elem.CreatorName,
            "CurrencySymbol" : resultCurrency != null && typeof resultCurrency.symbol != 'undefined' ? 
                              resultCurrency.symbol : 
                              typeof elem.defaults.currency.symbol != 'undefined' ? elem.defaults.currency.symbol : '$'
          });
          vm.topFundraisersForAllEvents.push(elem);
        }
      });
    },

    openPage(pageUrl) {
      window.location = pageUrl;
    },

    sortAndSliceLeaderboardToSize() {
      var sorted = this.topFundraisersForAllEvents.sort(
        (a, b) => b.TotalRaw - a.TotalRaw
      );
      this.topFundraisersForAllEvents = sorted.slice(0, this.size);      
      this.topFundraisersForAllEvents.forEach((elem, index) => {
        elem["LeaderboardPosition"] = index + 1;
      });
    },

    //TODO: need to remove this (not in use)
    getTopFundraisingPageForEvent(eventId) {
      var pagesSearchUrl = `https://${this.apiDomain}/v1/pages/search?eventcampaignid=${eventId}&pagesize=1&pageindex=1`;
      return axios.get(pagesSearchUrl);
    }
  }
};
</script>

<style scoped lang="css">
.gf-etflb-img {
  width: 65px;
  height: 65px;
  border-radius: 50%;
  object-fit: cover;
}

.pointer {
  cursor: pointer;
}
</style>
