<template>
  <div class="container border rounded p-2">      
    <div class="row">
      <div class="col-3 border-0 p-0 m-0 text-center rounded">              
        <a :href="page.pageUrl">
          <img
            :src="page.imageUrl"
            :alt="page.title"
            class="col-12 gf-frp-search-img w-100"
          >           
        </a>
      </div>
      <div class="col-9">
        <div>
          <h3>{{ page.title }}</h3>
        </div>
      </div>
    </div>
    <div class="row">
      <div class="col-12">
        <div>
          <div class="h6 gf-frp-search-item-eventname text-center">
            {{ page.eventName }}
          </div>
        </div>
      </div>
    </div>
    <div class="row justify-content-center text-center">
      <div class="col">
        ${{ page.raised }}
      </div>
    </div>
    <div class="row justify-content-center">
      <div class="col-md-6 col-xs-10">
        <a
          class="gf-frp-search-btn btn btn-primary w-100"
          :href="page.donateUrl"
        >Donate</a>
      </div>
    </div>
  </div>
</template>
<script>
export default {
    props:{
        page: {
            type: Object,
            default: () => {}
        },
    }
}
</script>