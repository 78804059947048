
<template lang="html">
  <div id="vue-gf-frp-search">
    <div class="">
      <div class="header-container-mobile">
        <div class="col-12">
          <div class="input-group">
            <input
              v-model="searchString"
              type="text"
              class="form-control"
              placeholder="Search"
              aria-label="Search"
              aria-describedby="basic-addon2"
              @keyup.enter="getFundraiserSearchResults"
            >
            <select
              v-if="settings.showPageTypeFilter"
              id="pageType"
              v-model="selectedPageType"
              @change="pageTypeChange"
            >
              <option
                v-for="pageType in pageTypes"
                :key="pageType.value"
                :value="pageType.value"
              >
                {{ pageType.text }}
              </option>
            </select>

            <div
              class="gf-frp-search-button"
              @click="getFundraiserSearchResults()"
            >
              <div class="input-group-append">
                <button class="gf-secondary-button gf-secondary-border transition btn btn-success ml-3">
                  Search
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
      <component
        :is="componentToLoad"
        :fundraiser-search-results="fundraiserSearchResults"
        :loading="loading"
        :hide-join-team="settings.pageId > 0 && settings.includeTeamMembers"
        @loadMore="performSearchAndUpdateResults"
      />
    </div>
  </div>
</template>

<script>
import axios from "axios";
import _ from "lodash";
import "promise-polyfill/src/polyfill";
import Base from './Base.vue';
import lookups from '../common/lookups'
import constants from '../common/constants';
import gfComponents from "./components";
import Loader from "vue-spinner/src/ClipLoader.vue";
import fundraiserSearchData from '../data/fundraiserSearchData.json'

export default {
  name: "FundraiserSearch",
  components: {Loader },
  mixins: [Base],

  props: {
    title: {
      type: String,
      default: "Search Fundraisers"
    },
    blockBackgroundColor: {
      type: String,
      default: ""
    },
    blockTextColor: {
      type: String,
      default: ""
    },
    settings: {
        type: Object,
        default: () => {
            return {
                showPageTypeFilter : false,
                pageId: 0,
                includeTeamMembers : false ,
                mode: { name: constants.FUNDRAISER_SEARCH_DEFAULT_MODE }
            };
        }
    },
    isDemoMode: {
      type: Boolean,
      default: false,
    }
  },

  data: function() {
    return {
      searchString: "",
      fundraiserSearchResults: [],
      driverButtonColour: "",
      driverTextColour: "",
      loading: false,
      morePages: true,
      currentPage: 1,
      pageTypes: [],
      selectedPageType: "",
      componentMode: '',
      requestController: false,
    };
  },
  computed: {
    componentToLoad() {
      if (!this.componentMode || this.componentMode == '')
          return null;

      return gfComponents.LoadComponent(this.componentMode);
    }
  },

  created() {
    if(this.settings && this.settings.mode) {
      this.componentMode = this.settings.mode.name;
    }else{
      this.componentMode = constants.FUNDRAISER_SEARCH_DEFAULT_MODE;
    }
  },

  mounted: function() {
    this.getPageTypes();
    this.getFundraiserSearchResults();
    this.driverButtonColour = this.blockBackgroundColor;
    this.driverTextColour = this.blockTextColor;
  },

  methods: {
    getPageTypes() {
      this.pageTypes = lookups.PAGE_TYPES;
      this.selectedPageType = this.pageType;
    },
    pageTypeChange(){
      this.getFundraiserSearchResults();
    },
    getFundraiserSearchResults() {
      this.morePages = true;
      this.currentPage = 1;

      const debounce = _.debounce(async () => {
        this.fundraiserSearchResults = [];
        await this.performSearchAndUpdateResults();
      }, 500)
      
      debounce();
    },

    async performSearchAndUpdateResults() {
      if (!this.morePages)
          return;

      this.loading = true;
      var results = await this.populateFundraisers(this.currentPage);
      
      if (results.Pages) {
        results.Pages.forEach((o) => {
          this.fundraiserSearchResults.push(o);
        });

        this.fundraiserSearchResults.concat(
          results.Pages
        );
      }

      this.loading= false;
      this.morePages = this.isMoreDataAvailable(results);
      this.currentPage += 1;
    },

    isMoreDataAvailable(response) {
      return (
        response.ResultsReturned == this.size &&
        response.PageNumber <= response.TotalPages
      );
    },

    async populateFundraisers(pageIndex) {
      var vm = this;
      var url = `https://${this.apiDomain}/v1/pages/search?query=${encodeURIComponent(this.searchString)}&region=${this.regionCode}&eventcampaignid=${this.eventId}&beneficiaryaccountid=${this.beneficiaryId}&sortby=4&excludepageswithnofundraising=true&pagesize=${this.size}&pageindex=${pageIndex}&tags=${this.tags}`;
      // do not filter by the pageType if include member is true
      var isIncludeMember = this.settings.includeTeamMembers && this.settings.pageId;
      if(vm.selectedPageType !== "" && !isIncludeMember)
          url +=`&pagetype=${this.selectedPageType}`;

      if(this.settings.pageId)
          url +=`&pageid=${this.settings.pageId}`;

      if (typeof this.settings.includeTeamMembers != 'undefined' && this.settings.includeTeamMembers !== null)
          url +=`&includeteammembers=${this.settings.includeTeamMembers}`;

      return await this.getFundraiserData(url);
    },

    async getFundraiserData(url) {
      const controller = new AbortController();
      
      if (this.requestController) {
        this.requestController.abort()
      }

      this.requestController = controller
      var res = (this.isDemoMode) 
          ? await new Promise(resolve => resolve(fundraiserSearchData))
          : await axios.get(url, { signal : controller.signal });

      return this.constructFundraiser(res.data) ;
    },

    constructFundraiser(data){
      var items = [];
      data.Pages.forEach(element => {
        var total =  element.PageType == constants.FUNDRAISING_PAGE_TEAM_TYPE_ID ? element.Team.TeamTotal : element.Total;
        items.push({
          id: element.Id,
          name: `${element.CreatorName}`,
          donateUrl: element.DonationUrl,
          raised: parseInt(total).toFixed(2),
          pageUrl: element.Url,
          imageUrl: element.ImagePath,
          eventImageUrl: element.Event.EventImagePath,
          eventName: element.Event.EventName,
          title: element.PageTitle,
          secondaryBgColor: element.Event.EventTheme.SecondaryButtonFontColour,
          eventHomeUrl: element.Event.EventUrl,
          pageType : element.PageType,
        });
      });

      return {
        Pages: items,
        ResultsReturned: data.ResultsReturned,
        TotalPages: data.TotalPages,
        PageNumber: data.PageNumber,
      };

    }
  }
};
</script>

<style lang="css" scoped>

.header-container-mobile > div > div.input-group{
    padding: 25px 0;
    margin-bottom: 0px !important;
}

.gf-frp-search-button .btn-success {
    border: 0;
    padding: 11px 25px;
}

.input-group>.custom-select:not(:last-child), #vue-gf-frp-search .input-group>.form-control:not(:last-child) {
    border-top-right-radius: .25rem;
    border-bottom-right-radius: .25rem;
    padding: 1.3rem .9rem;
    font-size: 14px;
    color: #000;
}

.form-control{
    border-color: #ccc;
}

.form-control::placeholder {
    color: #c7c7c7;
}

.container > .row{
    width:100%;
    margin-left: 0;
    margin-right: 0;
}

.pointer {
    cursor: pointer;
}

select#pageType {
    border: 1px solid #ccc;
    margin-left: 0.5rem;
    padding-left: 10px;
    font-size: 14px;
    border-radius: .25rem;
    width: 45%;
}

@media (max-width: 640px){
    .input-group > * {
        width: 100% !important;
        margin-left: 0 !important;
    }

    select#pageType{
        height: 38px;
        margin-top: 15px;
    }

    .input-group-append .btn {
        margin-left: 0 !important;
        margin-top: 15px;
        width: 100%;
    }
}

</style>
