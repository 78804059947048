<script>
export default {
  props: {
    beneficiaryId: {
      type: String,
      default: "",
    },
    includeSubBeneficiaries: {
      type: Boolean,
      default: false,
    },
    includeParentBeneficiary: {
      type: Boolean,
      default: true,
    },
  },
};
</script>
