import constants from './constants'

export default {
  state: {
    currencySymbol: constants.CURRENCY,
  },

  setCurrencySymbol(symbol) {
    this.state.currencySymbol = symbol
  }
}