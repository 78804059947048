<template lang="html">
  <div id="vue-gf-frp-leaderboard">
    <div class="container">
      <div class="row mb-2">
        <div class="h1">
          {{ title }}
        </div>
      </div>
      <div class="row">
        <div
          v-for="fundraiser in topFundraisers"
          :key="fundraiser.id"
          class="col-6 col-md-4 col-lg-2 border p-0 m-0 border-0"
        >
          <div class="dummy" />
          <div class="frp-block">
            <fundraiser-block
              :fundraiser="fundraiser"
              :block-background-color="driverButtonColour"
              :text-color="driverTextColour"
            />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import axios from 'axios'
import Base from './Base.vue';
import FundraiserBlock from './FundraiserBlock.vue'
import 'promise-polyfill/src/polyfill'
import constants from '../common/constants';

export default {
  name: 'TopFundraisers',

  components: {
    FundraiserBlock
  },

  mixins: [Base],

  props: {
    title: {
      type: String,
      default: 'Top Fundraisers'
    },
    beneficiaryId: {
      type: String,
      default: ''
    },
    blockBackgroundColor: {
      type: String,
      default: ''
    },
    blockTextColor: {
      type: String,
      default: ''
    }
  },

  data: function() {
    return {
      topFundraisers: [],
      driverButtonColour: '',
      driverTextColour: '',
    };
  },

  mounted: function() {
    this.getTopFundraisers();
    this.driverButtonColour = this.blockBackgroundColor;
    this.driverTextColour = this.blockTextColor;
  },

  methods: {
    getTopFundraisers() {
      var vm = this;
      axios.get(`https://${this.apiDomain}/v1/pages/search?eventcampaignid=${this.eventId}&beneficiaryaccountid=${this.beneficiaryId}&pagetype=${this.pageType}&pagesize=${this.size}&sortorder=${this.sortOrder}&sortby=${this.sortBy}&tags=${this.tags}`)
      .then(res => {
        res.data.Pages.forEach(element => {
          var total =  element.PageType == constants.FUNDRAISING_PAGE_TEAM_TYPE_ID ? element.Team.TeamTotal : element.Total;          
          vm.topFundraisers.push({
            id: element.Id,
            name: `${element.CreatorName}`,
            raised: total,
            pageUrl: element.Url,
            imageUrl: element.ImagePath,
            title: element.PageTitle
          });

          if (vm.driverButtonColour === '')
            vm.driverButtonColour = element.Event.EventTheme.DriverButtonBackgroundColour;

          if (vm.driverTextColour === '')
            vm.driverTextColour = element.Event.EventTheme.DriverButtonFontColour;
        });
      });
    }
  }
}
</script>

<style lang="css" scoped>
.dummy {
    margin-top: 100%;
}
.frp-block {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
}
</style>
